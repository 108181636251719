import { logError } from '@tapestry/shared/utils';
import axios from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.NEXT_PUBLIC_ACTIVITIES_REST_API_URL,
});

// Add request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('accessToken');

    // TODO will need to handle public operations
    if (!token) {
      return Promise.reject(new Error('No access token found'));
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  (error) => {
    logError(`Request error: ${error}`, { error });

    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Handle errors
    if (error.response) {
      // Server responded with a status other than 2xx
      logError(`Response error: ${error.response}`, { error: error.response });
    } else if (error.request) {
      // Request was made but no response was received
      logError(`No response received: ${error.request}`, {
        error: error.request,
      });
    } else {
      // Something happened in setting up the request that triggered an error
      logError(`Error ${error.message}`, { error: error.message });
    }

    return Promise.reject(error);
  }
);

export default axiosClient;
