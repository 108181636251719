import { CalendarIcon } from '@tapestry/shared/icons';
import { dateTime } from '@tapestry/shared/utils';
import React, { FC, ReactNode } from 'react';
import { Button } from '../../Button';
import { twMerge } from 'tailwind-merge';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { parseJSDateToISOString } from '../../Calendar/calendar-utils';
import { ISOStringsDateRange } from '../../Calendar/types';

dayjs.extend(quarterOfYear);

export type PeriodPreset = { label: string; value: [Date, Date] };

interface IPresetsPanelProps {
  handleSelectDate: (dates: ISOStringsDateRange) => void;
  setShouldShowPresets: (choice: boolean) => void;
  isCalShowing?: boolean;
}

interface IWithPresetWrapper {
  withPresets: boolean;
  shouldShowPresets: boolean;
  handleSelectDate: (dates: ISOStringsDateRange) => void;
  handleTogglePresetsPanelAndRecalcPositionning: (choice: boolean) => void;
  isPhone: boolean;
  children: ReactNode;
}

const NOW = dateTime.now();

export const PRESET_PERIOD_OPTIONS: Array<PeriodPreset> = [
  {
    label: 'Last Week',
    value: [
      NOW.subtract(1, 'week').startOf('isoWeek').toDate(),
      NOW.subtract(1, 'week').endOf('isoWeek').toDate(),
    ],
  },
  {
    label: 'Last Month',
    value: [
      NOW.subtract(1, 'month').startOf('month').toDate(),
      NOW.subtract(1, 'month').endOf('month').toDate(),
    ],
  },
  {
    label: 'Last Quarter',
    value: [
      NOW.subtract(1, 'quarter').startOf('quarter').toDate(),
      NOW.subtract(1, 'quarter').endOf('quarter').toDate(),
    ],
  },
  {
    label: 'Last Year',
    value: [
      NOW.subtract(1, 'year').startOf('year').toDate(),
      NOW.subtract(1, 'year').endOf('year').toDate(),
    ],
  },

  {
    label: 'Rolling Week',
    value: [NOW.subtract(7, 'day').toDate(), NOW.toDate()],
  },
  {
    label: 'Rolling Month',
    value: [NOW.subtract(1, 'month').toDate(), NOW.toDate()],
  },
  {
    label: 'Rolling Quarter',
    value: [NOW.subtract(1, 'quarter').toDate(), NOW.toDate()],
  },
  {
    label: 'Rolling Year',
    value: [NOW.subtract(1, 'year').toDate(), NOW.toDate()],
  },

  {
    label: 'This Week',
    value: [NOW.startOf('isoWeek').toDate(), NOW.endOf('isoWeek').toDate()],
  },
  {
    label: 'This Month',
    value: [NOW.startOf('month').toDate(), NOW.endOf('month').toDate()],
  },
  {
    label: 'This Quarter',
    value: [NOW.startOf('quarter').toDate(), NOW.endOf('quarter').toDate()],
  },
  {
    label: 'This Year',
    value: [NOW.startOf('year').toDate(), NOW.endOf('year').toDate()],
  },
];

/**
 * Pannel with presetted date range (last week, last month, etc)
 */
export const PresetsPanel: FC<React.PropsWithChildren<IPresetsPanelProps>> = ({
  handleSelectDate,
  setShouldShowPresets,
  isCalShowing,
}) => {
  const handlePresetSelection = (value: PeriodPreset['value']) => {
    const parsedDates = parseJSDateToISOString(value);
    handleSelectDate(parsedDates as ISOStringsDateRange);
  };

  return (
    <div
      className={twMerge(
        'w-full max-w-full rounded-b-lg bg-white p-4 sm:w-auto sm:rounded-t-lg',
        isCalShowing ? 'sm:rounded-b-none' : 'shadow-xl'
      )}
    >
      <ul className="grid w-full grid-flow-row grid-cols-2 gap-2 sm:grid-flow-col sm:grid-cols-3 sm:grid-rows-5 sm:gap-1">
        {PRESET_PERIOD_OPTIONS.map((period) => (
          <li key={period.label} className="w-full">
            <Button
              status="basic"
              fullWidth
              spacing="small"
              onClick={() => {
                handlePresetSelection(period.value);
              }}
              className="h-full"
            >
              {period.label}
            </Button>
          </li>
        ))}

        <li className="col-span-2 col-start-1 row-start-7 w-full sm:col-span-3 sm:col-start-1 sm:row-start-5">
          <Button
            status="basic"
            fullWidth
            spacing="small"
            onClick={() => setShouldShowPresets(false)}
            iconRight={<CalendarIcon className="h-4 w-auto" />}
          >
            Custom Dates
          </Button>
        </li>
      </ul>
    </div>
  );
};

/**
 * A wrapper that shows a preset panels when required
 */
export const WithPresetsWrapper: FC<
  React.PropsWithChildren<IWithPresetWrapper>
> = ({
  withPresets,
  shouldShowPresets,
  handleSelectDate,
  handleTogglePresetsPanelAndRecalcPositionning,
  isPhone,
  children: calendar,
}) => {
  return withPresets && shouldShowPresets ? (
    <PresetsPanel
      handleSelectDate={handleSelectDate}
      setShouldShowPresets={handleTogglePresetsPanelAndRecalcPositionning}
    />
  ) : (
    <div
      className={twMerge(
        'flex flex-col rounded-lg bg-white sm:items-center xl:flex xl:flex-row-reverse',
        withPresets && 'shadow-xl'
      )}
    >
      {withPresets && !isPhone && (
        <PresetsPanel
          handleSelectDate={handleSelectDate}
          setShouldShowPresets={handleTogglePresetsPanelAndRecalcPositionning}
          isCalShowing={!shouldShowPresets}
        />
      )}

      {calendar}

      {withPresets && (
        <div className="max-w-full rounded-b-lg bg-white p-4 pt-0 shadow-xl sm:hidden">
          <Button
            status="basic"
            fullWidth
            spacing="small"
            onClick={() => handleTogglePresetsPanelAndRecalcPositionning(true)}
          >
            Show Presets
          </Button>
        </div>
      )}
    </div>
  );
};
