import { dateTime } from '@tapestry/shared/utils';
import { NullableDateRange } from '../../Calendar/types';

const SIDEBAR_WIDTH = 64;

export const getPopperModifiers = (isPhone: boolean) => {
  return [
    {
      name: 'preventOverflow',
      options: {
        padding: {
          left: isPhone ? 8 : SIDEBAR_WIDTH,
          right: isPhone ? 8 : 16,
        },
      },
    },
  ];
};

export const formatDatesToDisplay = (dates: NullableDateRange) => {
  const [startDate, endDate] = dates;
  const start = startDate
    ? dateTime.parseInUTC(startDate).format('DD/MM/YYYY')
    : '';
  const end = endDate ? dateTime.parseInUTC(endDate).format('DD/MM/YYYY') : '';

  return `${start} - ${end}`;
};
